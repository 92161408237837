import BaseStore from 'fluxible/addons/BaseStore';

class AbStore extends BaseStore {
  constructor(dispatcher) {
    super(dispatcher);
    this.docExperiments = {};
  }

  getDocExperiments() {
    return this.docExperiments;
  }

  handleAbDataLoaded(payload) {
    this.docExperiments = payload.docExperiments;
    this.emitChange();
  }

  dehydrate() {
    return {
      docExperiments: this.docExperiments,
    };
  }

  rehydrate(state) {
    this.docExperiments = state.docExperiments;
  }
}

AbStore.storeName = 'AbStore';
AbStore.handlers = {
  LOAD_AB_DATA: 'handleAbDataLoaded',
};

export default AbStore;
