var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import canUseDOM from '../util/canUseDOM';
import { GoogleTagManager } from './GoogleTagManager';
import { LinkedinInsight } from './LinkedinInsight';
import { Status } from './util';
/**
 * AnalyticsScriptLoader is used to load any scripts containing cookies which require user consent before they can be set
 * This way we block the use of the cookies until the user has given consent
 */
export var AnalyticsScriptLoader = function () {
    var _a;
    var initialConsents = canUseDOM() ? (_a = window.OnetrustActiveGroups) !== null && _a !== void 0 ? _a : '' : '';
    var _b = __read(React.useState(initialConsents), 2), currentConsents = _b[0], setCurrentConsents = _b[1];
    React.useEffect(function () {
        var handleConsentChange = function (e) {
            if (e.data === Status.WaitingForConsent) {
                window.OneTrust.OnConsentChanged(function () {
                    setCurrentConsents(window.OnetrustActiveGroups);
                });
            }
            else if (e.data === Status.ConsentExpressed) {
                setCurrentConsents(window.OnetrustActiveGroups);
            }
        };
        window.addEventListener('message', handleConsentChange, false);
        return function () {
            window.removeEventListener('message', handleConsentChange, false);
        };
        1;
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(GoogleTagManager, { currentConcents: currentConsents }),
        React.createElement(LinkedinInsight, { currentConcents: currentConsents })));
};
