import * as React from 'react';
import { useScript } from '../hooks/useScript';
import { CookieConsentLevel, hasRequiredLevel } from './util';
var REQUIRED_CONSENT_LEVEL = CookieConsentLevel.Functional;
var scriptAttributes = {
    async: true,
    type: 'text/javascript',
    text: "\n    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-W7FRLJ');\n  ",
};
var noscriptHtml = '<noscript><iframe src=​"https://www.googletagmanager.com/ns.html?id=GTM-W7FRLJ" height=​"0"​ width=​"0"​ style=​"display:none;visibility:hidden"​/></noscript>';
export var useGoogleTagManager = function (currentConcents) {
    var hasConsent = hasRequiredLevel(currentConcents, REQUIRED_CONSENT_LEVEL);
    useScript('head', scriptAttributes, hasConsent);
};
export var GoogleTagManager = function (_a) {
    var currentConcents = _a.currentConcents;
    useGoogleTagManager(currentConcents);
    return hasRequiredLevel(currentConcents, REQUIRED_CONSENT_LEVEL) ? (React.createElement("div", { dangerouslySetInnerHTML: { __html: noscriptHtml } })) : null;
};
