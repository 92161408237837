var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import ErrorPage from './pages/ErrorPage';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { error: null };
        return _this;
    }
    ErrorBoundary.prototype.componentDidCatch = function (error, info) {
        this.setState({ error: error });
        // @ts-ignore
        if (typeof window !== 'undefined' && window.env.SENTRY_DSN) {
            /* eslint-disable global-require */
            var LoggingService = require('../services/LoggingService.client');
            LoggingService.captureMessage(error.message, {
                err: error,
                extra: info,
            });
        }
    };
    ErrorBoundary.prototype.render = function () {
        return this.state.error ? React.createElement(ErrorPage, { error: this.state.error }) : this.props.children;
    };
    return ErrorBoundary;
}(React.Component));
export default ErrorBoundary;
