import { useEffect } from 'react';
/**
 * useScript is a hook for appending script elements to the body of the document
 * @param {string} appendTo where in the document will the script be appended to, e.g. head, body
 * @param {object} attributes script element attributes such as src, type, text and async
 * @param {boolean} shouldLoad allows to conditionally apend/remove the script
 */
export var useScript = function (appendTo, attributes, shouldLoad) {
    if (shouldLoad === void 0) { shouldLoad = true; }
    if (typeof document === 'undefined') {
        return;
    }
    useEffect(function () {
        if (shouldLoad) {
            var script_1 = document.createElement('script');
            Object.assign(script_1, attributes);
            document[appendTo].appendChild(script_1);
            return function () {
                document[appendTo].removeChild(script_1);
            };
        }
    }, [attributes, shouldLoad]);
};
