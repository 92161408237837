/* global Raven */
/* eslint-disable no-console */
import Raven from 'raven-js';
import normalizeError from '../util/normalizeError';

const INFO = 'info';
const WARNING = 'warning';
const ERROR = 'error';

if (window.env.SENTRY_DSN) {
  Raven.config(window.env.SENTRY_DSN).install();
}

const LoggingService = {};

LoggingService.debug = (msg, data) => {
  if (process.env.NODE_ENV !== 'production') {
    console.debug(msg, data);
  }
};

LoggingService.info = (msg, extra) => {
  LoggingService.captureMessage(msg, { level: INFO, extra });
};

LoggingService.warn = (msg, extra) => {
  LoggingService.captureMessage(msg, { level: WARNING, extra });
};

LoggingService.error = (msg, extra) => {
  LoggingService.captureMessage(msg, { level: ERROR, extra });
};

LoggingService.captureMessage = (msg, data = {}) => {
  if (window.env.SENTRY_DSN) {
    if (msg instanceof Error) {
      Raven.captureException(msg, data);
    } else if (data.err && data.extra.err) {
      const err = data.extra.err;
      const normalizedDataErr = Object.assign({}, { ...data, err: normalizeError(err) });

      Raven.captureMessage(msg, normalizedDataErr);
    } else {
      Raven.captureMessage(msg, data);
    }
  } else if (data.level === INFO) {
    console.info(msg, data.extra);
  } else if (data.level === WARNING) {
    console.warn(msg, data.extra);
  } else if (data.level === ERROR) {
    console.error(msg, data.extra);
  }
};

export default LoggingService;
