import d from 'debug';
import { createElementWithContext } from 'fluxible-addons-react';
import React from 'react';
import ReactDOM from 'react-dom';

import app from './app';
import DocumentService from './services/DocumentService.client';
import LoggingService from './services/LoggingService.client';
import NavigationService from './services/NavigationService.client';

require('./styles/docs.styl');

const debug = d('docs:react');
const dehydratedState = window.App; // Sent from the server

window.React = React; // For chrome dev tool support

// expose debug object to browser, so that it can be enabled/disabled from browser:
// https://github.com/visionmedia/debug#browser-support
window.fluxibleDebug = debug;

// Register services
const plugin = app.getPlugin('ServiceProxyPlugin');
plugin.registerService('DocumentService', DocumentService);
plugin.registerService('LoggingService', LoggingService);
plugin.registerService('NavigationService', NavigationService);
plugin.registerService('FetchService', window.fetch);

debug('rehydrating app');

// pass in the dehydrated server state from server.js
app.rehydrate(dehydratedState, (err, context) => {
  if (err) {
    throw err;
  }

  window.context = context;

  const element = createElementWithContext(context);
  const mountPoint = document.getElementById('app');

  debug('React Rendering');
  ReactDOM.hydrate(element, mountPoint, () => {
    debug('React Rendered');
    // Remove SSR styles once client-side rendering takes over
    const ssrStyleEl = document.getElementById('jss-server-side');
    if (ssrStyleEl) {
      ssrStyleEl.parentNode.removeChild(ssrStyleEl);
    }
  });
});
