import { track } from '@a0/docs-components/metrics';
export default function createMetricsPlugin() {
    return {
        name: 'MetricsPlugin',
        plugContext: function () { return ({
            plugComponentContext: function (componentContext) {
                componentContext.trackEvent = track;
                componentContext.trackPage = function () {
                    return;
                };
            },
            plugActionContext: function (actionContext) {
                actionContext.trackEvent = track;
                actionContext.trackPage = function () {
                    return;
                };
            },
            dehydrate: function () {
                //
            },
            rehydrate: function () { return null; },
        }); },
    };
}
