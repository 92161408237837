import Fluxible from 'fluxible';
import devToolsPlugin from 'fluxible-plugin-devtools';
import serviceProxyPlugin from 'fluxible-plugin-service-proxy';
import { hot } from 'react-hot-loader';
import Application from './components/Application';
import metricsPlugin from './plugins/metricsPlugin';
import AbStore from './stores/AbStore';
import ApplicationStore from './stores/ApplicationStore';
import DocumentStore from './stores/DocumentStore';
import NavigationStore from './stores/NavigationStore';
import QuickstartStore from './stores/QuickstartStore';
import RouteStore from './stores/RouteStore';
import SearchStore from './stores/SearchStore';
import StaticContentStore from './stores/StaticContentStore';
import UserStore from './stores/UserStore';
// create new fluxible instance
var app = new Fluxible({
    component: hot(module)(Application),
});
// register plugins
app.plug(serviceProxyPlugin());
app.plug(metricsPlugin());
app.plug(devToolsPlugin());
// register stores
app.registerStore(RouteStore);
app.registerStore(ApplicationStore);
app.registerStore(DocumentStore);
app.registerStore(NavigationStore);
app.registerStore(QuickstartStore);
app.registerStore(SearchStore);
app.registerStore(StaticContentStore);
app.registerStore(UserStore);
app.registerStore(AbStore);
export default app;
