export var CookieConsentLevel;
(function (CookieConsentLevel) {
    CookieConsentLevel["Necessary"] = "1";
    CookieConsentLevel["Performance"] = "2";
    CookieConsentLevel["Functional"] = "3";
    CookieConsentLevel["Advertising"] = "4";
})(CookieConsentLevel || (CookieConsentLevel = {}));
export var Status;
(function (Status) {
    Status["WaitingForConsent"] = "WAITING_FOR_CONSENT";
    Status["ConsentExpressed"] = "CONSENT_EXPRESSED";
})(Status || (Status = {}));
export var hasRequiredLevel = function (consentLevels, requiredLevel) {
    return (consentLevels === null || consentLevels === void 0 ? void 0 : consentLevels.includes(requiredLevel)) || false;
};
