import { useScript } from '../hooks/useScript';
import { CookieConsentLevel, hasRequiredLevel } from './util';
var REQUIRED_CONSENT_LEVEL = CookieConsentLevel.Functional;
var setLinkedinIdScript = {
    async: false,
    type: 'text/javascript',
    text: 'var _linkedin_data_partner_id = "30812";',
};
var scriptAttributes = {
    async: true,
    type: 'text/javascript',
    src: 'https://snap.licdn.com/li.lms-analytics/insight.min.js',
};
export var useLinkedinInsight = function (currentConcents) {
    var hasConsent = hasRequiredLevel(currentConcents, REQUIRED_CONSENT_LEVEL);
    useScript('head', setLinkedinIdScript, hasConsent);
    useScript('head', scriptAttributes, hasConsent);
};
export var LinkedinInsight = function (_a) {
    var currentConcents = _a.currentConcents;
    useLinkedinInsight(currentConcents);
    return null;
};
